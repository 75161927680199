
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import Api from '@/api/index'
const addressData = require('@/plugins/provinces.json')
@Options({
  props: {
    modal: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: '标题'
    }
  },
  components: {},
  watch: {
    modal(v) {
      return v
    }
  }
})
export default class extends SuperVue {
  // props
  modal!: boolean
  title!: string

  // data
  submitData = {
    seletedList: [] as any[],
    showSelectedList: [
      {
        avatar: 'https://picsum.photos/68/68',
        name: '李威A',
        sex: 1,
        post: '副高级',
        industry_category: '制造业',
        area: '广州',
        score: 4,
        attitude_score: 3,
        quality_score: 1,
        timeliness_score: 4,
        patent_num: 108,
        pass_patent_num: 50
      },
      {
        avatar: 'https://picsum.photos/68/68',
        name: '李威B',
        sex: 2,
        post: '副高级',
        industry_category: '制造业',
        area: '广西',
        score: 4,
        attitude_score: 3,
        quality_score: 1,
        timeliness_score: 4,
        patent_num: 108,
        pass_patent_num: 100
      },
      {
        avatar: 'https://picsum.photos/68/68',
        name: '李威B',
        sex: 2,
        post: '副高级',
        industry_category: '制造业',
        area: '广西',
        score: 4,
        attitude_score: 3,
        quality_score: 1,
        timeliness_score: 4,
        patent_num: 108,
        pass_patent_num: 100
      }
    ] as any[],
    deadline: ''
  }
  expertList = [
    {
      avatar: 'https://picsum.photos/68/68',
      name: '李威A',
      sex: 1,
      post: '副高级',
      industry_category: '制造业',
      area: '广州',
      score: 4,
      attitude_score: 3,
      quality_score: 1,
      timeliness_score: 4,
      patent_num: 108,
      pass_patent_num: 50
    },
    {
      avatar: 'https://picsum.photos/68/68',
      name: '李威B',
      sex: 2,
      post: '副高级',
      industry_category: '制造业',
      area: '广西',
      score: 4,
      attitude_score: 3,
      quality_score: 1,
      timeliness_score: 4,
      patent_num: 108,
      pass_patent_num: 100
    }
  ]
  attitudeOptions = ['', '极差', '差', '一般', '较好', '好']
  qualityOptions = ['', '极差', '差', '一般', '较好', '好']
  timelinessOptions = ['', '极不', '不', '较', '', '很']
  sort = 1
  searchBox = {
    industry_category: [],
    post_rank: '',
    nature: '',
    sex: '',
    min_age: null,
    max_age: null,
    area: [],
    scale_: 1,
    scale: 3,
    expert_num: null,
    industryOptions: [] as any[],
    postRankOptions: [
      { value: '无', label: '无' },
      { value: '初级', label: '初级' },
      { value: '中级', label: '中级' },
      { value: '副高级', label: '副高级' },
      { value: '正高级', label: '正高级' }
    ],
    natureOptions: [
      { label: '政府单位', value: 0 },
      { label: '高校', value: 1 },
      { label: '科研机构', value: 2 },
      { label: '科技服务机构', value: 3 },
      { label: '医院', value: 4 },
      { label: '社会团体', value: 5 },
      { label: '其他事业单位', value: 6 },
      { label: '军队武警', value: 7 },
      { label: '国企', value: 8 },
      { label: '民企', value: 9 },
      { label: '外企', value: 10 },
      { label: '混合所有制企业', value: 11 },
      { label: '境外机构', value: 12 },
      { label: '其他组织', value: 13 }
    ],
    sexOptions: [
      { label: '男', value: 1 },
      { label: '女', value: 2 }
    ],
    addressOptions: addressData.data
  }
  options = [
    {
      value: 'guide',
      label: 'Guide',
      children: [
        {
          value: 'disciplines',
          label: 'Disciplines',
          children: [
            {
              value: 'consistency',
              label: 'Consistency'
            },
            {
              value: 'feedback',
              label: 'Feedback'
            },
            {
              value: 'efficiency',
              label: 'Efficiency'
            },
            {
              value: 'controllability',
              label: 'Controllability'
            }
          ]
        },
        {
          value: 'navigation',
          label: 'Navigation',
          children: [
            {
              value: 'side nav',
              label: 'Side Navigation'
            },
            {
              value: 'top nav',
              label: 'Top Navigation'
            }
          ]
        }
      ]
    }
  ]
  created() {
    this.getIndustry()
  }
  // @Description 排序, @Author 清平, @Date 2022/04/18 星期一 16:06:33, @Version v2
  sortChange(type: number) {
    this.sort = type
  }
  // @Description 获取行业分类, @Author 清平, @Date 2022/04/18 星期一 14:47:50, @Version v2
  getIndustry() {
    Api.http_getCategoryList({ offset: 0, length: 1000 }).then((res) => {
      this.searchBox.industryOptions = res.data.data
    })
  }
  // @Description 确认, @Author 清平, @Date 2022/04/20 星期三 17:46:21, @Version v2
  ok() {
    this.$emit('ok', this.submitData)
  }
  // @Description 关闭弹窗, @Author 清平, @Date 2022/04/18 星期一 14:47:28, @Version v2
  close() {
    this.$emit('close')
  }
}
