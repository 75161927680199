
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import DeptUserTree from './component/dept-user-tree.vue'
import RecommendExpert from '@/components/recommend-expert/RecommendExpert.vue'
import Config from '@/config'
import { httpRequest } from '@/plugins/index'

@Options({
  components: {
    PagePlugin,
    CustomDialog,
    DeptUserTree,
    RecommendExpert
  }
})
export default class my_patent extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  tb = {
    page: 1,
    pageSize: 10
  }
  pageChange({ currentPage, pageSize }) {
    this.tb.page = currentPage
    this.tb.pageSize = pageSize
  }
  appeal_dialog = {
    open: false,
    title: '申请申诉',
    remarks: '',
    id: -1
  }
  appeal_dialog_init(row) {
    this.appeal_dialog.remarks = ''
    this.appeal_dialog.open = true
    this.appeal_dialog.id = row.id
  }
  appeal_dialog_ok() {
    if (!this.appeal_dialog.remarks) {
      return this.$message.error('请填写申诉理由')
    }
    httpRequest
      .post('api/v1/my_patent/t/addAppeals', {
        patent_id: this.appeal_dialog.id,
        report_return_reason: this.appeal_dialog.remarks
      })
      .then((res: any) => {
        if (res.code === '200') {
          this.$message.success('提交申述成功')
          this.t_get_all_data(
            (this.tb.page - 1) * this.tb.pageSize,
            this.tb.pageSize
          )
          this.appeal_dialog.open = false
        }
      })
  }

  resultFormOpt = Config.resultForm

  dialogA = {
    open: true,
    title: '智能匹配专家'
  }

  // 添加发明人-start
  users_dialog = {
    open: false,
    title: '邀请发明人',
    users: [] as any[],
    user_ids: [] as number[],
    is_edit: false // 是否为编辑窗
  }
  addPerson(is_edit: boolean) {
    if (is_edit) {
      this.users_dialog.users = this.t_edit_data.person_info.inventor
      this.users_dialog.user_ids = this.t_edit_data.person_info.inventor.map(
        (item) => item.id
      )
    } else {
      this.users_dialog.users = this.t_add_data.person_info.inventor
      this.users_dialog.user_ids = this.t_add_data.person_info.inventor.map(
        (item) => item.id
      )
    }
    this.users_dialog.is_edit = is_edit
    this.users_dialog.open = true
  }
  delPerson(is_edit: boolean, row: any) {
    // console.log(row)
    if (is_edit) {
      this.t_edit_data.person_info.inventor
        = this.t_edit_data.person_info.inventor.filter(
          (item) => item.id !== row.id
        )
      if (!this.t_edit_data.person_info.inventor.length) {
        this.t_edit_data.person_info.inventor = [] as any[]
      }
    } else {
      this.t_add_data.person_info.inventor
        = this.t_add_data.person_info.inventor.filter(
          (item) => item.id !== row.id
        )
      if (!this.t_add_data.person_info.inventor.length) {
        this.t_add_data.person_info.inventor = [] as any[]
      }
    }
  }
  users_dialog_cancle() {
    this.users_dialog.open = false
  }
  users_dialog_ok() {
    this.users_dialog.open = false
    if (this.users_dialog.is_edit) {
      this.t_edit_data.person_info.inventor = this.users_dialog.users
    } else {
      this.t_add_data.person_info.inventor = this.users_dialog.users
    }
  }
  // end

  t_all_data = []

  t_total = 0
  t_search_data = {
    start_time: '',
    end_time: '',
    name: '',
    type: '',
    status: '',
    order_by: '',
    sort: ''
  }

  rule_t_search_data = {}

  t_add_dialog = {
    open: false,
    title: '添加专利'
  }

  t_add_data = {
    id: '' as string | number,
    person_info: {
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      dept: '',
      inventor: [
        // { name: '', id_number: '', dept: '', duty: '', phone: '' }
      ] as any[]
    },
    base_info: {
      name: '',
      type: 0,
      number: '',
      patent_type: 1,
      industry_involved: [],
      related_industry: [],
      patentee: '',
      purpose: '',
      result_type: 3 as string | number,
      result_form: '新发现',
      _result_form: '',
      has_others: 0 as string | number,
      others_msg: [{ name: '', num: '' }] as any,
      digest: '', // 摘要
      digest_file: [] as any,
      claim_rights: '', // 权利要求书
      claim_rights_file: [] as any,
      specification: '', // 说明书
      specification_file: [] as any,
      invention_member: ''
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: [] as any,
      max_price: 0,
      min_price: 0,
      permit: '',
      permit_select: '',
      permit_amount: 0
    },
    self_info: []
  }
  t_add_data_copy = {
    id: '' as string | number,
    person_info: {
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      dept: '',
      inventor: [
        // { name: '', id_number: '', dept: '', duty: '', phone: '' }
      ] as any[]
    },
    base_info: {
      name: '',
      patent_type: 1,
      number: '',
      purpose: '',
      type: 0,
      industry_involved: [],
      related_industry: [],
      patentee: '',
      result_type: 3 as string | number,
      result_form: '新发现',
      _result_form: '',
      has_others: 0 as string | number,
      others_msg: [{ name: '', num: '' }] as any,
      digest: '', // 摘要
      digest_file: [] as any,
      claim_rights: '', // 权利要求书
      claim_rights_file: [] as any,
      specification: '', // 说明书
      specification_file: [] as any,
      invention_member: ''
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: [] as any,
      max_price: 0,
      min_price: 0,
      permit: '',
      permit_select: '',
      permit_amount: 0
    },
    self_info: []
  }

  result_type_change(e) {
    Utils.log('e', e)
    this.get_WeightList(-1, [0, 13, 14, 15][e])
  }

  add_has_others_change(e) {
    if (e === 1) {
      if (this.t_add_data.base_info.others_msg.length === 0) {
        this.t_add_data.base_info.others_msg.push({ name: '', num: '' })
      }
    }
  }
  edit_has_others_change(e) {
    if (e === 1) {
      if (!Array.isArray(this.t_edit_data.base_info.others_msg)) {
        this.t_edit_data.base_info.others_msg = [{ name: '', num: '' }]
      }
      if (this.t_edit_data.base_info.others_msg.length === 0) {
        this.t_edit_data.base_info.others_msg.push({ name: '', num: '' })
      }
    }
  }
  rule_t_add_data = {
    'person_info.contact': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.phone': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      {
        pattern: /^1[345789]\d{9}$/,
        message: '请填写正确手机号',
        trigger: 'blur'
      }
    ],
    'person_info.email': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      { type: 'email', message: '邮箱格式不正确', trigger: 'blur' }
    ],
    'person_info.address': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.duty': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.dept': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.name': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    // 'base_info.number': [
    //   { required: true, message: '请填写完整', trigger: 'blur' }
    // ],
    'base_info.type': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.industry_involved': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.patentee': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.invention_member': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.result_type': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.has_others': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.digest': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.claim_rights': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.specification': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.significance': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.question': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.plan': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.max_price': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.min_price': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.expect': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.conditions': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.permit': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.permit_select': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ]
  }

  t_detail_dialog = {
    open: false,
    title: '详情'
  }

  t_detail_data = {
    person_info: {
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      inventor: [] as any[]
    },
    base_info: {
      name: '',
      type: 0,
      industry_involved: [],
      related_industry: [],
      patentee: '',
      digest: '', // 摘要
      claim_rights_file: '' as any,
      digest_file: '' as any,
      specification_file: '' as any,
      claim_rights: '', // 权利要求书
      specification: '', // 说明书
      result_type: 3 as string | number,
      result_form: '',
      _result_form: '',
      has_others: 0 as string | number,
      others_msg: [] as any
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: '' as any,
      max_price: 0,
      min_price: 0
    },
    self_info: []
  }

  t_del_dialog = {
    open: false,
    title: '删除'
  }

  t_del_data = {
    id: ''
  }

  t_edit_dialog = {
    open: false,
    title: '编辑'
  }

  t_edit_data = {
    id: '',
    person_info: {
      dept: '',
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      inventor: [] as any[]
    },
    base_info: {
      name: '',
      type: 0,
      number: '',
      industry_involved: [],
      related_industry: [],
      patentee: '',
      result_type: 3 as string | number,
      result_form: '新发现',
      _result_form: '',
      has_others: 0 as string | number,
      others_msg: [] as any,
      digest: '', // 摘要
      digest_file: [] as any,
      claim_rights: '', // 权利要求书
      claim_rights_file: [] as any,
      specification: '', // 说明书
      specification_file: [] as any,
      invention_member: ''
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: [] as any,
      max_price: 0,
      min_price: 0,
      permit: '',
      permit_select: '',
      permit_amount: 0
    },
    self_info: [] as any
  }

  rule_t_edit_data = {
    'person_info.contact': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.phone': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      {
        pattern: /^1[345789]\d{9}$/,
        message: '请填写正确手机号',
        trigger: 'blur'
      }
    ],
    'person_info.email': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      { type: 'email', message: '邮箱格式不正确', trigger: 'blur' }
    ],
    'person_info.address': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.duty': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.dept': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.name': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.has_others': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    // 'base_info.number': [
    //   { required: true, message: '请填写完整', trigger: 'blur' }
    // ],
    'base_info.type': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.result_type': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.result_form': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.industry_involved': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.patentee': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.invention_member': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.digest': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.claim_rights': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.specification': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.significance': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.question': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.plan': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.max_price': [
      {
        type: 'number',
        required: true,
        message: '请填写完整',
        trigger: 'blur'
      },
      {
        pattern: /^[1-9]\d*(\.\d+)?$/,
        message: '填写数值必须大于0',
        trigger: 'blur'
      }
    ],
    'conversion_situation.min_price': [
      {
        type: 'number',
        required: true,
        message: '请填写完整',
        trigger: 'blur'
      },
      {
        pattern: /^[1-9]\d*(\.\d+)?$/,
        message: '填写数值必须大于0',
        trigger: 'blur'
      }
    ],
    'conversion_situation.expect': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.conditions': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.permit': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.permit_select': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ]
  }

  t_edit1_dialog = {
    open: false,
    title: '提交'
  }

  t_edit1_data = {
    id: ''
  }

  rule_t_edit1_data = {}

  getIndustry: any[] = []
  getIndustry2: any[] = []
  WeightList: any[] = []
  type_654 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])
  status_657 = new Map<any, string>([
    [0, '草稿'],
    [1, '待成员确认'],
    [2, '待指派组长'],
    [3, '待分配专家'],
    [4, '待专家评'],
    [5, '待组长审核'],
    [6, '审核通过'],
    [7, '审核不通过'],
    [8, '待审核申诉'],
    [9, '成员退回'],
    [10, '申诉审核不通过'],
    [11, '申诉审核通过']
  ])
  type_676 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])

  t_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      start_time: this.t_search_data.start_time,
      end_time: this.t_search_data.end_time,
      name: this.t_search_data.name,
      type: this.t_search_data.type,
      status: this.t_search_data.status,
      patent_type: 1,
      order_by: this.t_search_data.order_by,
      sort: this.t_search_data.sort
    }
    Api.http_my_patentt0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        // console.log('a',res);
        this.t_all_data = res.data.data
        this.t_total = res.data.total
      }
    })
  }

  t_add_init(row) {
    this.get_getIndustry(row.id)
    this.get_getIndustry2(row.id)
    this.get_WeightList(row.id)
    this.t_add_dialog.open = true
    this.setDialogName(this.t_add_dialog.title)
  }

  t_add_cancle() {
    this.t_add_dialog.open = false
    this.t_add_data = this.t_add_data_copy
    this.removeDialogName()
  }

  t_add_ok() {
    const t_add_data = JSON.parse(JSON.stringify(this.t_add_data))
    const postData = {
      id: t_add_data.id,
      person_info: t_add_data.person_info,
      base_info: t_add_data.base_info,
      supplement: t_add_data.supplement,
      conversion_situation: t_add_data.conversion_situation,
      self_info: t_add_data.self_info
    }
    postData.conversion_situation.expect = JSON.stringify(
      postData.conversion_situation.expect
    )
    postData.base_info.digest_file = JSON.stringify(
      postData.base_info.digest_file
    )
    if (
      this.resultFormOpt.find(
        (item) => item === postData.base_info.result_form
      ) === '其他'
    ) {
      postData.base_info.result_form = postData.base_info._result_form
    }
    if (postData.base_info.has_others) {
      postData.base_info.others_msg = JSON.stringify(
        postData.base_info.others_msg
      )
    } else {
      postData.base_info.others_msg = JSON.stringify([])
    }
    postData.base_info.claim_rights_file = JSON.stringify(
      postData.base_info.claim_rights_file
    )
    postData.base_info.specification_file = JSON.stringify(
      postData.base_info.specification_file
    )
    Api.http_my_patentt1(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_add_dialog.open = false
        this.removeDialogName()
        this.t_get_all_data(
          (this.tb.page - 1) * this.tb.pageSize,
          this.tb.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
        this.t_add_data = this.t_add_data_copy
      }
    })
    //   }
    // })
  }

  t_del_init(row) {
    this.t_del_data.id = row.id
    this.t_del_dialog.open = true
  }
  t_del_cancle() {
    this.t_del_dialog.open = false
  }

  t_del_ok() {
    const postData = {
      id: this.t_del_data.id
    }
    Api.http_my_patentt2(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_del_dialog.open = false
        this.removeDialogName()
        this.t_get_all_data(
          (this.tb.page - 1) * this.tb.pageSize,
          this.tb.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
      }
    })
  }
  t_detail_init(row) {
    this.get_getIndustry(row.id)
    this.get_getIndustry2(row.id)
    this.get_WeightList(row.id)
    Api.http_my_patenttget4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_detail_dialog.open = true
        console.warn('专利详情 :>> ', res.data)
        this.t_detail_data.person_info = res.data.person_info
        this.t_detail_data.base_info = res.data.base_info
        this.t_detail_data.supplement = res.data.supplement
        this.t_detail_data.conversion_situation = res.data.conversion_situation
        this.t_detail_data.self_info = res.data.self_info
        this.setDialogName(this.t_detail_dialog.title)
        if (Utils.isJSON(this.t_detail_data.conversion_situation.expect)) {
          this.t_detail_data.conversion_situation.expect = JSON.parse(
            this.t_detail_data.conversion_situation.expect
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.others_msg)) {
          this.t_detail_data.base_info.others_msg = JSON.parse(
            this.t_detail_data.base_info.others_msg
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.claim_rights_file)) {
          this.t_detail_data.base_info.claim_rights_file = JSON.parse(
            this.t_detail_data.base_info.claim_rights_file
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.digest_file)) {
          this.t_detail_data.base_info.digest_file = JSON.parse(
            this.t_detail_data.base_info.digest_file
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.specification_file)) {
          this.t_detail_data.base_info.specification_file = JSON.parse(
            this.t_detail_data.base_info.specification_file
          )
        }
      }
    })
  }

  t_detail_cancle() {
    this.t_detail_dialog.open = false
    this.removeDialogName()
  }

  t_detail_ok() {
    this.t_detail_dialog.open = false
  }

  again_add_init(row) {
    this.get_getIndustry(row.id)
    this.get_getIndustry2(row.id)
    this.get_WeightList(row.id)
    Api.http_my_patenttget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_add_data.id = -1
        this.t_add_data.person_info = res.data.person_info
        this.t_add_data.base_info = res.data.base_info
        if (
          !this.resultFormOpt.find(
            (item) => item === this.t_add_data.base_info.result_form
          )
        ) {
          this.t_add_data.base_info._result_form
            = this.t_add_data.base_info.result_form
          this.t_add_data.base_info.result_form = '其他'
        }
        this.t_add_data.supplement = res.data.supplement
        this.t_add_data.conversion_situation = res.data.conversion_situation
        this.t_add_data.self_info = res.data.self_info
        this.t_add_dialog.open = true
        const expect = JSON.parse(this.t_add_data.conversion_situation.expect)
        this.t_add_data.conversion_situation.expect
          = typeof expect === 'object' ? expect : []
        this.t_add_data.base_info.digest_file = JSON.parse(
          this.t_add_data.base_info.digest_file
        )
        this.t_add_data.base_info.claim_rights_file = JSON.parse(
          this.t_add_data.base_info.claim_rights_file
        )
        this.t_add_data.base_info.specification_file = JSON.parse(
          this.t_add_data.base_info.specification_file
        )
        this.setDialogName(this.t_edit_dialog.title)
        this.t_add_data.base_info.others_msg = JSON.parse(
          this.t_add_data.base_info.others_msg
        )
      }
    })
  }

  t_edit_init(row) {
    this.get_getIndustry(row.id)
    this.get_getIndustry2(row.id)
    this.get_WeightList(row.id)
    Api.http_my_patenttget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit_data.id = row.id
        this.t_edit_data.person_info = res.data.person_info
        this.t_edit_data.base_info = res.data.base_info
        if (
          !this.resultFormOpt.find(
            (item) => item === this.t_edit_data.base_info.result_form
          )
        ) {
          this.t_edit_data.base_info._result_form
            = this.t_edit_data.base_info.result_form
          this.t_edit_data.base_info.result_form = '其他'
        }
        this.t_edit_data.supplement = res.data.supplement
        this.t_edit_data.conversion_situation = res.data.conversion_situation
        this.t_edit_data.self_info = res.data.self_info
        this.t_edit_dialog.open = true
        const expect = JSON.parse(this.t_edit_data.conversion_situation.expect)
        this.t_edit_data.conversion_situation.expect
          = typeof expect === 'object' ? expect : []
        this.t_edit_data.base_info.digest_file = JSON.parse(
          this.t_edit_data.base_info.digest_file
        )
        this.t_edit_data.base_info.claim_rights_file = JSON.parse(
          this.t_edit_data.base_info.claim_rights_file
        )
        this.t_edit_data.base_info.specification_file = JSON.parse(
          this.t_edit_data.base_info.specification_file
        )
        this.setDialogName(this.t_edit_dialog.title)
        this.t_edit_data.base_info.others_msg = JSON.parse(
          this.t_edit_data.base_info.others_msg
        )
      }
    })
  }

  t_edit_cancle() {
    this.t_edit_dialog.open = false
    this.removeDialogName()
  }

  t_edit_ok() {
    const t_edit_data = JSON.parse(JSON.stringify(this.t_edit_data))
    const postData = {
      id: t_edit_data.id,
      person_info: t_edit_data.person_info,
      base_info: t_edit_data.base_info,
      supplement: t_edit_data.supplement,
      conversion_situation: t_edit_data.conversion_situation,
      self_info: t_edit_data.self_info
    }
    postData.conversion_situation.expect = JSON.stringify(
      postData.conversion_situation.expect
    )
    postData.base_info.digest_file = JSON.stringify(
      postData.base_info.digest_file
    )
    postData.base_info.claim_rights_file = JSON.stringify(
      postData.base_info.claim_rights_file
    )
    if (
      this.resultFormOpt.find(
        (item) => item === postData.base_info.result_form
      ) === '其他'
    ) {
      postData.base_info.result_form = postData.base_info._result_form
    }
    if (postData.base_info.has_others) {
      postData.base_info.others_msg = JSON.stringify(
        postData.base_info.others_msg
      )
    } else {
      postData.base_info.others_msg = JSON.stringify([])
    }
    postData.base_info.specification_file = JSON.stringify(
      postData.base_info.specification_file
    )
    Api.http_my_patentt5(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit_dialog.open = false
        this.removeDialogName()
        this.t_get_all_data(
          (this.tb.page - 1) * this.tb.pageSize,
          this.tb.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
      }
    })
  }

  t_edit1_init() {
    console.warn('编辑信息 :>> ', this.t_edit_data)
    this.$refs.ref_t_edit_data.validate((valid) => {
      if (valid) {
        Api.http_my_patenttget6({ id: this.t_edit_data.id }).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.t_edit1_data.id = this.t_edit_data.id
            this.t_edit1_dialog.open = true
          }
        })
      }
    })
  }

  t_edit1_cancle() {
    this.t_edit1_dialog.open = false
  }

  t_edit1_ok() {
    const t_edit_data = JSON.parse(JSON.stringify(this.t_edit_data))
    const postData = {
      id: t_edit_data.id,
      person_info: t_edit_data.person_info,
      base_info: t_edit_data.base_info,
      supplement: t_edit_data.supplement,
      conversion_situation: t_edit_data.conversion_situation,
      self_info: t_edit_data.self_info
    }
    postData.conversion_situation.expect = JSON.stringify(
      postData.conversion_situation.expect
    )
    postData.base_info.digest_file = JSON.stringify(
      postData.base_info.digest_file
    )
    postData.base_info.claim_rights_file = JSON.stringify(
      postData.base_info.claim_rights_file
    )
    if (
      this.resultFormOpt.find(
        (item) => item === postData.base_info.result_form
      ) === '其他'
    ) {
      postData.base_info.result_form = postData.base_info._result_form
    }
    if (postData.base_info.has_others) {
      postData.base_info.others_msg = JSON.stringify(
        postData.base_info.others_msg
      )
    } else {
      postData.base_info.others_msg = JSON.stringify([])
    }
    postData.base_info.specification_file = JSON.stringify(
      postData.base_info.specification_file
    )
    Api.http_my_patentt5(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit_dialog.open = false
        this.removeDialogName()
        this.t_get_all_data(
          (this.tb.page - 1) * this.tb.pageSize,
          this.tb.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
        // 保存成功，提交数据
        this.$refs.ref_t_edit1_data.validate((valid) => {
          if (valid) {
            const postData1 = {
              id: this.t_edit1_data.id
            }
            Api.http_my_patentt6(postData1).then((res1) => {
              if (res1.code !== '200') {
                this.$message.error(res1.message)
              } else {
                this.t_edit1_dialog.open = false
                this.t_edit_dialog.open = false
                this.t_get_all_data(
                  (this.tb.page - 1) * this.tb.pageSize,
                  this.tb.pageSize
                )
                this.$message.success(JSON.stringify(res1.message))
              }
            })
          }
        })
      }
    })
  }

  t_init() {
    this.t_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
  }
  get_getIndustry(id = -1) {
    Api.http_my_patenttgetIndustry0({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.getIndustry = res.data.data
        }
      }
    )
  }
  get_getIndustry2(id = -1) {
    Api.http_my_patenttgetIndustry20({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.getIndustry2 = res.data.data
        }
      }
    )
  }
  get_WeightList(id = -1, type_id = 13) {
    Api.http_my_patenttWeightList0({
      id: id,
      offset: 0,
      length: 999,
      type_id
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.WeightList = res.data.data
      }
    })
  }
  created() {
    this.t_init()
  }
}
